.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000008f;
  z-index: 1000;
  display: flex;
}
.modal {
  width: 350px;
  min-height: 100px;
  max-height: 80%;
  background: white;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding: 50px 0 50px 0;
  text-align: center;
  display: flex;
}
.modal-header {
  position: absolute;
  width: 100%;
  top: 0;
  height: 50px;
  background: #ECEDEC;
  color: #C1C2C1;
  font-size: 1.4rem;
  text-align: center;
  line-height: 52px;
  text-transform: capitalize;
}
.modal-body {
  max-height: calc(80% - 50px - 50px);
  width: 100%;
  overflow-y: auto;
  background-color: white;
  color:#BDBEBD;
}
.modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #63BB57;
  color: white;
  float: right;
  font-size: 1.4rem;
  line-height: 52px;  
  text-transform: capitalize;
}
.modal-footer:hover {
  cursor: pointer;
}
