.top-scorers {
  padding: 15px;
}
.top-scorers ul {
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.top-scorers li.active {
  color: #63bb57;
  cursor: default;
}
.top-scorers svg {
  height: 16px;
  margin-left: -4px;
}
