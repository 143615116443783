.game {
  padding: 25px;
}
.main {
  margin: auto;
  overflow: hidden;
  border-radius: 8px;
}
.header {
  display: flex;
  padding: 10px 12px;
  height: 50px;
  max-height: 50px;
  background: black;
  color: deeppink;
  font-size: 25px;
}
.header > div {
  margin-top: 15px;
}
.header .right {
  width: 100%;
  text-align: right;
}
.header .center {
  width: 100%;
  text-align: center;
}
.header .left {
  width: 100%;
  text-align: left;
}

/*** Mobile - Landscape ***/
@media only screen and (min-device-width: 481px) and (max-device-width: 992px) and (orientation: landscape) {
  .main {
    float: left;
  }
}
